import React, { useEffect, useState } from 'react'
import logo from './assets/hyprlink_white.svg';
import header from './assets/header.svg';
import Card from './components/Card';
import cameron from './assets/cameron.svg'
import michael from './assets/michael.svg'
import justin from './assets/justin.svg'
import notifications from './assets/notifications_preview.svg'
import profile from './assets/profile.svg'
import createevent from './assets/createevent.svg'
import facebook from './assets/Facebook.svg'
import twitter from './assets/Twitter.svg'
import loyolan from './assets/loyolan.svg'
import instagram from './assets/Instagram.svg'
import mapView from './assets/map.svg'
import Navbar from './components/Navbar'
import Button from './components/Button';
import './App.css';
import { Link } from 'react-scroll'
import useWindowDimensions from './hooks/useWindowDimensions';

function App() {
  const screen = useWindowDimensions()
  const MIN_WIDTH = 950

  return (
    <div className="App-header">
      <div className='content-window'>
        <Navbar/>
        <img src={header} style={{width:"100%", borderRadius: 15}} />
        <Card id='home'>
          <h2>Find fun events near you</h2>
          <span>
            Looking for something to do on a Friday night? Hyprlink's got you covered!
            Create an account and discover events in your area! Hyprlink brings the events
            to you. Attend events in your local area, or create events of your own! Hyprlink
            is the casual event-finding and event-promotion platform for everyone.
          </span>
          <br/>
          <a href="https://discord.gg/XKNM7H6x8e" target='_blank' style={{
          display:'flex',
          flexDirection:'column',
          textDecoration:'none',
          width:'100%',
          alignItems:'center'
        }}>
          <Card style={{borderColor:"#8E47FF", backgroundColor:'#0F0F0F', width:'auto'}}>
            <span style={{color:'#8E47FF'}}>Hyprlink is now in beta testing. Join today by clicking here!</span>
          </Card>
        </a>
        </Card>
        <br/>
        <Card id='about'>
          <h1>About Us</h1>
          <span>
            Learn about our story and how Hyprlink became what it is today.
          </span>
          <br/><br/>
          <h3>The Problems We're Solving</h3>
          <span>
            It's Friday night. You're done with all of your work, and now you want to go and do
            something fun. But you don't know any events going on in the area, and you don't know
            anyone to go with! <strong>Or worse</strong>, you find an event to attend, but you're not sure if it's safe.
          </span>
          <br/>
          <span>
            Or maybe you're an event promoter or host, frantically flipping through 10-15 different social apps to try and
            get as many people as you can to show up to your event. Shouldn't there be a better, more streamlined way to
            get your event out there to your target audience? And how can you assure them that your event is going to
            be <strong>safe</strong>?
          </span>
          <br/><br/>
          <h3>Our Solution</h3>
          <span>
            We're solving this problem with our mobile app, Hyprlink. Hyprlink helps people find local events easier,
            and makes hosting and attending events more accessible than ever. It also enables people to find events they
            trust, and makes event promotion simple.
          </span>
          <br/><br/>
          <h3>Why we Want to Solve These Problems</h3>
          <span>
            Each of us on the team realize the value of safety when it comes to going to events. We hear all over campus about tragic
            occurrences that take place at events, which creates skepticism and fear surrounding future events. We want to be able to
            help decrease the amount of these tragic occurrences. We also want to help people connect with others, and attend events that
            match their interests. Granting people the ease of finding safe and interesting events is our top priority.
          </span>
          <br/><br/>
          { screen.width > 900 ? (
            <div className='center'>
              <div className='row' style={{justifyContent:'space-between', width: '60%'}}>
                <div className='col'>
                  <img src={justin} width={75} style={{marginBottom:10}}/>
                  <h5 style={{textAlign:'center'}}>Justin Yee</h5>
                  <Button href='https://www.linkedin.com/in/justin-yee529/' color="#33A1FD">LinkedIn</Button>
                </div>
                <div className='col'>
                  <img src={cameron} width={75} style={{marginBottom:10}}/>
                  <h5 style={{textAlign:'center'}}>Cameron Gould</h5>
                  <Button href='https://www.linkedin.com/in/cameron-gould' color="#33A1FD">LinkedIn</Button>
                </div>
                <div className='col'>
                  <img src={michael} width={75} style={{marginBottom:10}}/>
                  <h5 style={{textAlign:'center'}}>Michael Reza</h5>
                  <Button href='https://www.linkedin.com/in/michael-reza-8359a0194/' color="#33A1FD">LinkedIn</Button>
                </div>
              </div>
            </div>
          ) : (
            <div className='center'>
              <div className='row' style={{justifyContent:'space-around', width:250}}>
                <div className='col'>
                  <img src={cameron} width={75} style={{marginBottom:10}}/>
                  <h5 style={{textAlign:'center'}}>Cameron Gould</h5>
                  <Button href='https://www.linkedin.com/in/cameron-gould' color="#33A1FD">LinkedIn</Button>
                </div>
                <div className='col'>
                  <img src={justin} width={75} style={{marginBottom:10}}/>
                  <h5 style={{textAlign:'center'}}>Justin Yee</h5>
                  <Button href='https://www.linkedin.com/in/justin-yee529/' color="#33A1FD">LinkedIn</Button>
                </div>
              </div>
              <br/>
              <div className='row' style={{justifyContent:'space-around', width:250}}>
                <div className='col'>
                  <img src={michael} width={75} style={{marginBottom:10}}/>
                  <h5 style={{textAlign:'center'}}>Michael Reza</h5>
                  <Button href='https://www.linkedin.com/in/michael-reza-8359a0194/' color="#33A1FD">LinkedIn</Button>
                </div>
              </div>
            </div>
          )
        }
          <h3>Our Team</h3>
          <span>
            Hyprlink is being developed by three Computer Science students at Loyola
            Marymount University. Michael is a Software Engineer at Oracle,
            and Cameron and Justin are both Software Development Engineers at
            Amazon. Our team is deeply interested in providing a high quality experience
            to as many people as possible, and our expertise in Computer Science guides
            every decision.
          </span>
        </Card>
        <br/>
        <h2>Features</h2>
        <p style={{textAlign:'center'}}>
          Hyprlink is a feature-rich event finder and creator application that is currently in
          a technical beta stage.
        </p>
        {screen.width > 800 ? (
          <div className='col'>
            <div className='row' style={{alignItems:'flex-start', justifyContent:'space-around'}}>
              <FeatureView src={mapView} title="Interactive Map View" imageWith={300} textWidth={300}>
                Finding events in your area has never been easier. With the map view,
                you're able to see all of the events near you at a glance.
              </FeatureView>
              <FeatureView src={notifications} title="Friends Network" imageWith={300} textWidth={300}>
                Finding events in your area has never been easier. With the map view,
                you're able to see all of the events near you at a glance.
              </FeatureView>
            </div>
            <div className='row' style={{alignItems:'flex-start', justifyContent:'space-around'}}>
              <FeatureView src={createevent} title="Event Creation" imageWith={300} textWidth={300}>
                Finding events in your area has never been easier. With the map view,
                you're able to see all of the events near you at a glance.
              </FeatureView>
              <FeatureView src={profile} title="Custimize Your Profile" imageWith={300} textWidth={300}>
                Finding events in your area has never been easier. With the map view,
                you're able to see all of the events near you at a glance.
              </FeatureView>
            </div>
          </div>
        ) : (
          <div className='col'>
            <FeatureView src={mapView} title="Interactive Map View" imageWith={300} textWidth={300}>
              Finding events in your area has never been easier. With the map view,
              you're able to see all of the events near you at a glance.
            </FeatureView>
            <FeatureView src={notifications} title="Friends Network" imageWith={300} textWidth={300}>
              Finding events in your area has never been easier. With the map view,
              you're able to see all of the events near you at a glance.
            </FeatureView>
            <FeatureView src={createevent} title="Event Creation" imageWith={300} textWidth={300}>
              Finding events in your area has never been easier. With the map view,
              you're able to see all of the events near you at a glance.
            </FeatureView>
            <FeatureView src={profile} title="Custimize Your Profile" imageWith={300} textWidth={300}>
              Customize your personal profile with a unique username, profile picture, and bio.
              You can also add your birthday and select your gender.
            </FeatureView>
          </div>
        )}
        <Card>
          <h3>Other Features</h3>
          <ul>
            <li>Discover popular events and hosts in your area with the explore page</li>
            <li>RSVP for other people's events, and edit or delete your own events</li>
            <li>View all of the upcoming events you've RSVP'd for</li>
            <li>Search for accounts and events</li>
            <li>And more!</li>
            </ul>
        </Card>
        <br/>
        <Card>
          <h3>Join us on Social Media</h3>
          <span>
            We're on social media! Follow us for the latest Hyprlink news and promotions.
          </span>
          <div className='row'>
            <img src={instagram} width={24} style={{marginRight:5}}/>
            <a className='link' style={{textDecoration:'none'}} target='_blank' href='https://instagram.com/hyprlink.us'>Instagram</a>
          </div>
          <div className='row'>
            <img src={twitter} width={24} style={{marginRight:5}}/>
            <a className='link' style={{textDecoration:'none'}} target='_blank' href='https://twitter.com/hyprlinkUS'>Twitter</a>
          </div>
          <div className='row'>
            <img src={facebook} width={24} style={{marginRight:5}}/>
            <a className='link' style={{textDecoration:'none'}} target='_blank' href='https://facebook.com/hyprlink.us'>Facebook</a>
          </div>
        </Card>
        <br/>
        <div className='col'>
          <span>As seen in</span>
          <a href="https://www.laloyolan.com/life_and_arts/lmu-student-creates-app-to-track-events-and-parties/article_a2b09661-f271-56d2-b51d-db411697e00c.html">
            <img src={loyolan} width={150}/>
          </a>
        </div>
        <br/>
        <Card style={{borderColor:"#FAFF00"}}>
          <h3>Get in on Hyprlink!</h3>
          <span>
            Hyprlink is now in a technical beta stage as of April 26th, 2022. As the app is rolled out to
            larger audiences, we are seeking collaboration with angel investors to help fund the app's marketing
            and technical expenses. If you are interested in hearing more about Hyprlink, the team behind it, and
            our business models, you can contact us
            at <a href="mailto:business@hyprlink.us" className='link'>business@hyprlink.us</a>.
          </span>
        </Card>
        <br/>
        <div className='row' style={{justifyContent:'space-evenly', marginBottom:10}}>
          <a className='link' style={{textDecoration:'none', fontSize:12}} href='mailto:support@hyprlink.us'>Support</a>
          <a className='link' style={{textDecoration:'none', fontSize:12}} href='mailto:business@hyprlink.us'>Business</a>
        </div>
        <div className='row' style={{justifyContent:'space-evenly', marginBottom:10}}>
          <a className='link' style={{textDecoration:'none', fontSize:12}} href='https://instagram.com/hyprlink.us'>Instagram</a>
          <a className='link' style={{textDecoration:'none', fontSize:12}} href='https://twitter.com/hyprlinkUS'>Twitter</a>
        </div>
        <div className='row' style={{justifyContent:'space-evenly'}}>
          <a className='link' style={{textDecoration:'none', fontSize:12}} href='https://facebook.com/hyprlink.us'>Facebook</a>
          <a className='link' style={{textDecoration:'none', fontSize:12}} href='https://discord.gg/XKNM7H6x8e'>Discord</a>
        </div>
      </div>
    </div>
  );
}


const FeatureView = (props) => {
  return (
    <div className='col' style={{margin: 5, width:'100%'}}>
      <Card style={{fontSize:12}}>
        <div className='col'>
          <img src={props.src} width={props.imageWith}/>
        </div>
        <h3 style={{marginTop:15}}>{props.title}</h3>
        <span>
          {props.children}
        </span>
      </Card>
    </div>
  )
}

export default App;
