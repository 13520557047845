import React from 'react'


const Button = (props) => {
    return (
        <a href={props.href} target='_blank' style={{textDecoration:'none'}}>
            <div className='button' style={{
                borderColor:props.color ? props.color: "#fff",
                color: props.color ? props.color: "#fff"
            }}>
                {props.children}
            </div>
        </a>
    )
}

export default Button