import React from 'react'
import logo from '../assets/hyprlink_white.svg';

const Navbar = (props) => {
    return (
        <div className='navbar'>
            <div className='nav-logo'>
                <img src={logo} width={40} style={{marginRight:10}}/>
                <div className='logo-text'>Hyprlink</div>
            </div>
        </div>
    )
}

export default Navbar